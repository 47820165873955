import * as React from 'react'
import { graphql } from 'gatsby'
import { useRecoilValue } from 'recoil'
import { isMobileState } from '@/recoil/layout'
import { Helmet } from 'react-helmet'
import Layout from '@/layouts/Default'
import ArticleHero from '@/components/ArticleHero'
import ArticleTitle from '@/components/ArticleTitle'
import ArticleBlockImage from '@/components/ArticleBlockImage'
import ArticleBlockText from '@/components/ArticleBlockText'
import ArticleBlockSimpleCaps from '@/components/ArticleBlockSimpleCaps'
import ArticleBlockHeadline from '@/components/ArticleBlockHeadline'
import ArticleBlockQuote from '@/components/ArticleBlockQuote'
import ArticleBlockCTA from '@/components/ArticleBlockCTA'
import ArticleBlockVideo from '@/components/ArticleBlockVideo'
import CardWithImage from '@/components/CardWithImage'
import BlockQuote from '@/components/Block/Quote'
import WinesSlider from '@/components/WinesSlider'
import NewsletterForm from '@/components/Forms/NewsletterForm'

import { NewsLetterWrapper, NewsLetterWrapperTitle, Wrapper, Wysiwyg } from '@/styles/pages/modular-page/style'

const ArticlePage = ({ data, pageContext: { requireAgeGate, pagePath, marketCode: pageMarketCode } }) => {
  const isMobile = useRecoilValue(isMobileState)
  const content = data.drupal.nodeById.fieldModularPageLayers

  const events = data.drupal.eventsQuery.entities.map((event) => (
    {
      name: event?.title,
      startDate: event?.fieldEventStartDate?.value,
      endDate: event?.fieldEventEndDate?.value,
      ref: event?.fieldEventLocation,
      hours: event?.fieldEventHours,
      country: event?.fieldEventCountryLocation,
      city: event?.fieldEventLocation,
      content: event?.fieldEventDescription,
      images: [
        event?.fieldNewsCover?.url
      ]
    }
  ))

  const handleClick = (value) => {
    value === 'SIGN UP' && window.dataLayer.push({
      event: 'sauvignon_sign_up'
    })
  }

  return (
    <Layout entityBundle='modular_page' path={ pagePath } requireAgeGate={ requireAgeGate } plainHeader pageType={ data.drupal.nodeById?.fieldModularPageMetaTags.entity.fieldMetaSlugUrl === 'our-certifications' ? 'Legal' : '' } pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>{data.drupal.nodeById?.fieldModularPageMetaTags.entity.fieldMetaTitle}</title>
        <meta name='description' content={ data.drupal.nodeById?.fieldModularPageMetaTags.entity.fieldMetaDescription } />
        <meta property='og:title' content={ data.drupal.nodeById?.fieldModularPageMetaTags.entity.fieldMetaTitle } />
        <meta property='og:description' content={ data.drupal.nodeById?.fieldModularPageMetaTags.entity.fieldMetaDescription } />
        <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
        <meta property='og:site_name' content='Cloudy Bay' />
        <meta property='og:type' content='website' />
        {data.drupal.nodeById?.fieldModularPageMetaTags.entity.fieldMetaOpenGraphImage && <meta property='og:image' content={ data.drupal.nodeById?.fieldModularPageMetaTags.entity.fieldMetaOpenGraphImage?.url } /> }
      </Helmet>

      <ArticleHero
        img={ isMobile ? data.drupal.nodeById?.fieldNewsCoverMobile?.url : data.drupal.nodeById?.fieldNewsCover?.url }
        hideBackButton={ content[0]?.entity?.fieldCtaBlockCta?.title !== 'back' }
        title={ data.drupal.nodeById?.fieldNewsTitle }
        introduction={ data.drupal.nodeById?.fieldNewsIntroduction }
        ctaLabel={ content[0]?.entity?.fieldCtaBlockCta?.title === 'back' ? content[0]?.entity?.fieldCtaBlockCta?.title : '' }
        cta={ content[0]?.entity?.fieldCtaBlockCta?.title === 'back' ? content[0]?.entity?.fieldCtaBlockCta?.url.path : '' }
      />

      {
        (content && content.length) &&
          <ArticleTitle
            hideBackButton={ content[0].entity?.fieldCtaBlockCta?.title !== 'back' }
            ctaLabel={ content[0].entity?.fieldCtaBlockCta?.title === 'back' ? content[0].entity?.fieldCtaBlockCta?.title : '' }
            cta={ content[0].entity?.fieldCtaBlockCta?.title === 'back' ? content[0].entity?.fieldCtaBlockCta?.url.path : '' }
          />
      }

      {content.map((row) => {
        switch (row.entity.type.targetId) {
          case 'simple_caps_section':
            return (
              <ArticleBlockSimpleCaps
                text={ row.entity.fieldSimpleCapsSectContent }
              />
            )

          case 'headline':
            return (
              <ArticleBlockHeadline
                text={ row.entity.fieldHeadlineContent }
              />
            )

          case 'simple_text_1_column':
            return (
              <ArticleBlockText
                text1={ row.entity.fieldSimpTxt1colSectContent }
              />
            )

          case 'simple_text_2_columns':
            return (
              <ArticleBlockText
                text1={ row.entity.fieldSimpTxt2colTxtColumn1 }
                text2={ row.entity.fieldSimpTxt2colTxtColumn2 }
              />
            )

          case 'simple_image':
            return (
              <ArticleBlockImage
                src={ isMobile ? row.entity.fieldSimpleImageMobile?.url : row.entity.fieldSimpleImageDesktop?.url }
              />
            )

          case 'standard_quote_with_author':
            return (

              row.entity.fieldStandardQuoteAuthorImgd
                ? <BlockQuote
                    img={ isMobile ? row.entity.fieldStandardQuoteAuthorImgm?.url : row.entity.fieldStandardQuoteAuthorImgd?.url }
                    author={ row.entity.fieldStandardQuoteAuthorName }
                    content={ row.entity.fieldStandardQuoteAuthorText }
                  />
                : <ArticleBlockQuote
                    author={ row.entity.fieldStandardQuoteAuthorName }
                    quote={ row.entity.fieldStandardQuoteAuthorText }
                  />

            )

          case 'cta_block':
            return (
              row.entity.fieldCtaBlockCta?.title === 'back'
                ? ''
                : <ArticleBlockCTA
                    to={ row.entity.fieldCtaBlockCta?.url?.path }
                    label={ row.entity.fieldCtaBlockCta?.title }
                    action={ () => { handleClick(row.entity.fieldCtaBlockCta?.title) } }
                    tracking={ row.entity.fieldCtaBlockCta?.title !== 'SIGN UP' }
                  />
            )

          case 'file_linik':
            return (
              <ArticleBlockCTA
                to={ row.entity.fieldStandardFile?.entity?.url }
                label={ row.entity.fieldStandardFile?.description }
                target='_blank'
              />
            )

          case 'standard_single_video':
            return (
              <ArticleBlockVideo
                url={ row.entity.fieldStandardSingleVimeoLink?.uri }
              />
            )

          case 'hero_block':
            return (
              <ArticleHero
                img={ isMobile ? row.entity.fieldBackgroundMobile.url : row.entity.fieldBackgroundDesktop.url }
                hideBackButton
                button={ row.entity.fieldHeroCta?.title && row.entity.fieldHeroCta?.url?.path }
                title={ row.entity.fieldHeroTitle }
                introduction={ row.entity.fieldHeroDescription }
                ctaLabel={ row.entity.fieldHeroCta?.title }
                cta={ row.entity.fieldHeroCta?.url?.path }
              />
            )

          case 'card_with_image':
            return (
              <CardWithImage
                content={ row.entity.fieldStandardDescription }
                title={ row.entity.fieldTitle }
                subtitle={ row.entity.fieldSubtitle }
                src={ isMobile ? row.entity.fieldSimpleImageMobile?.url : row.entity.fieldSimpleImageDesktop?.url }
                alt={ isMobile ? row.entity.fieldSimpleImageMobile?.alt : row.entity.fieldSimpleImageDesktop?.alt }
                direction={ row.entity.fieldCardWithImageLayout }
                label={ row.entity.fieldStandardCta?.title }
                to={ row.entity.fieldStandardCta?.url?.path }
              />
            )

          case 'product_widget' :

            const widgetCategory = row.entity.fieldWineList.map((category) => ({
              cat: category.entity.fieldWineProductCategoryName
            }))

            const products = data.drupal.productsQuery.entities

            const result = products.filter(element => {
              return widgetCategory.find(category => category.cat === element.name)
            })

            const productsSliderItems = result.map((category) => (
              {
                img: category.fieldWineCatPrimaryVintage.entity.fieldWineProdPackshotShadow.url,
                art: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackillustrat.url,
                name: category.name,
                price: category.fieldWineCategoryFromPrice,
                colorBg: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackcolor,
                colorCta: category.fieldWineCatPrimaryVintage.entity.fieldProductDiscoverColorCta,
                title: category.fieldWineCatPrimaryVintage.entity.fieldCategoryProductName,
                text: category.fieldWineCatPrimaryVintage.entity.fieldWineProductShortDesc,
                path: category.fieldWineCatPrimaryVintage.entity.path.alias
              }
            ))

            return (
              <WinesSlider
                id='textcercle1'
                title={ row.entity.fieldTitle }
                items={ productsSliderItems }
                label={ row.entity.fieldStandardCta.title }
                to={ row.entity.fieldStandardCta.url.path }
              />
            )
          case 'modular_newsletter':
            return (
              <NewsLetterWrapper>
                <NewsLetterWrapperTitle>
                  { row.entity.fieldNewsletterTitle }
                </NewsLetterWrapperTitle>
                {
                  (row?.entity?.fieldNewsletterText && row?.entity?.fieldNewsletterText.length > 0) && (
                    <Wysiwyg>{ row?.entity?.fieldNewsletterText }</Wysiwyg>
                  )
                }
                <NewsletterForm
                  modular
                  source={ row.entity.fieldNewsletterOrigin }
                  type={ parseFloat(row?.entity?.fieldNewsletterType || 0) }
                  conditions={ row?.entity?.fieldNewsletterConditions || [] }
                  confirmationText={ row?.entity?.fieldNewsletterConfirmation || null }
                />
              </NewsLetterWrapper>
            )
          case 'modular_column_cards':
            if (row.entity.fieldColumnCard.length === 3) {
              return (
                <Wrapper className='three-column'>
                  <CardWithImage
                    content={ row.entity.fieldColumnCard[0].entity.fieldDescription }
                    title={ row.entity.fieldColumnCard[0].entity.fieldTitle }
                    subtitle={ row.entity.fieldColumnCard[0].entity.fieldSubtitle }
                    src={ isMobile ? row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageMobile?.url : row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageDesktop?.url }
                    alt={ isMobile ? row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageMobile?.alt : row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageDesktop?.alt }
                    label={ row.entity.fieldColumnCard[0]?.entity.fieldStandardCta?.title }
                    to={ row.entity.fieldColumnCard[0]?.entity.fieldStandardCta?.url.path }
                    custom
                  />
                  <CardWithImage
                    content={ row.entity.fieldColumnCard[1].entity.fieldDescription }
                    title={ row.entity.fieldColumnCard[1].entity.fieldTitle }
                    subtitle={ row.entity.fieldColumnCard[1].entity.fieldSubtitle }
                    src={ isMobile ? row.entity.fieldColumnCard[1]?.entity.fieldSimpleImageMobile?.url : row.entity.fieldColumnCard[1]?.entity.fieldSimpleImageDesktop?.url }
                    alt={ isMobile ? row.entity.fieldColumnCard[1]?.entity.fieldSimpleImageMobile?.alt : row.entity.fieldColumnCard[1]?.entity.fieldSimpleImageDesktop?.alt }
                    label={ row.entity.fieldColumnCard[1]?.entity.fieldStandardCta?.title }
                    to={ row.entity.fieldColumnCard[1]?.entity.fieldStandardCta?.url.path }
                    custom
                  />
                  <CardWithImage
                    content={ row.entity.fieldColumnCard[2].entity.fieldDescription }
                    title={ row.entity.fieldColumnCard[2].entity.fieldTitle }
                    subtitle={ row.entity.fieldColumnCard[2].entity.fieldSubtitle }
                    src={ isMobile ? row.entity.fieldColumnCard[2]?.entity.fieldSimpleImageMobile?.url : row.entity.fieldColumnCard[2]?.entity.fieldSimpleImageDesktop?.url }
                    alt={ isMobile ? row.entity.fieldColumnCard[2]?.entity.fieldSimpleImageMobile?.alt : row.entity.fieldColumnCard[2]?.entity.fieldSimpleImageDesktop?.alt }
                    label={ row.entity.fieldColumnCard[2]?.entity.fieldStandardCta?.title }
                    to={ row.entity.fieldColumnCard[2]?.entity.fieldStandardCta?.url.path }
                    custom
                  />
                </Wrapper>
              )
            }
            if (row.entity.fieldColumnCard.length === 2) {
              return (
                <Wrapper className='two-column'>
                  <CardWithImage
                    content={ row.entity.fieldColumnCard[0].entity.fieldDescription }
                    title={ row.entity.fieldColumnCard[0].entity.fieldTitle }
                    subtitle={ row.entity.fieldColumnCard[0].entity.fieldSubtitle }
                    src={ isMobile ? row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageMobile?.url : row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageDesktop?.url }
                    alt={ isMobile ? row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageMobile?.alt : row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageDesktop?.alt }
                    label={ row.entity.fieldColumnCard[0]?.entity.fieldStandardCta?.title }
                    to={ row.entity.fieldColumnCard[0]?.entity.fieldStandardCta?.url.path }
                    custom
                  />
                  <CardWithImage
                    content={ row.entity.fieldColumnCard[1].entity.fieldDescription }
                    title={ row.entity.fieldColumnCard[1].entity.fieldTitle }
                    subtitle={ row.entity.fieldColumnCard[1].entity.fieldSubtitle }
                    src={ isMobile ? row.entity.fieldColumnCard[1]?.entity.fieldSimpleImageMobile?.url : row.entity.fieldColumnCard[1]?.entity.fieldSimpleImageDesktop?.url }
                    alt={ isMobile ? row.entity.fieldColumnCard[1]?.entity.fieldSimpleImageMobile?.alt : row.entity.fieldColumnCard[1]?.entity.fieldSimpleImageDesktop?.alt }
                    label={ row.entity.fieldColumnCard[1]?.entity.fieldStandardCta?.title }
                    to={ row.entity.fieldColumnCard[1]?.entity.fieldStandardCta?.url.path }
                    custom
                  />
                </Wrapper>
              )
            }
            if (row.entity.fieldColumnCard.length === 1) {
              return (
                <Wrapper className='one-column'>
                  <CardWithImage
                    content={ row.entity.fieldColumnCard[0].entity.fieldDescription }
                    title={ row.entity.fieldColumnCard[0].entity.fieldTitle }
                    subtitle={ row.entity.fieldColumnCard[0].entity.fieldSubtitle }
                    src={ isMobile ? row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageMobile?.url : row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageDesktop?.url }
                    alt={ isMobile ? row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageMobile?.alt : row.entity.fieldColumnCard[0]?.entity.fieldSimpleImageDesktop?.alt }
                    label={ row.entity.fieldColumnCard[0]?.entity.fieldStandardCta?.title }
                    to={ row.entity.fieldColumnCard[0]?.entity.fieldStandardCta?.url.path }
                    custom
                  />
                </Wrapper>
              )
            }
        }
      })}
    </Layout>
  )
}

export default ArticlePage

export const pageQuery = graphql`
query ModularPageQuery($nid: String!, $marketId: String!) {
  drupal {
    nodeById(id: $nid) {
      ... on Drupal_NodeModularPage {
        nid
        fieldNewsTitle
        fieldNewsIntroduction
        fieldNewsCoverMobile {
          alt
          url
          title
        }
        fieldNewsCover {
          alt
          title
          url
        }
        fieldModularPageMetaTags {
          entity {
            ... on Drupal_ParagraphMetaTags {
              fieldMetaTitle
              fieldMetaDescription
              fieldMetaOpenGraphImage {
                url
              }
              fieldMetaSlugUrl
            }
          }
        }
        fieldModularPageLayers {
          entity {
            id
            parentFieldName
            type {
              targetId
            }
            ... on Drupal_ParagraphFileLinik {
              id
              fieldStandardFile {
                description
                entity {
                  ... on Drupal_File {
                    url
                  }
                }
              }
            }

            ... on Drupal_ParagraphHeroBlock {
              id
              fieldHeroDescription
              fieldHeroTitle
              fieldHeroSubtitle
              fieldHeroCta {
                url {
                  path
                }
                title
              }
              fieldBackgroundMobile {
                alt
                url
              }
              fieldBackgroundDesktop {
                url
                alt
              }
            }

            ... on Drupal_ParagraphSimpleText1Column {
              id
              fieldSimpTxt1colSectContent
            }
            ... on Drupal_ParagraphHeadline {
              id
              fieldHeadlineContent
            }
            ... on Drupal_ParagraphSimpleCapsSection {
              id
              fieldSimpleCapsSectContent
            }
            ... on Drupal_ParagraphSimpleText2Columns {
              id
              fieldSimpTxt2colTxtColumn1
              fieldSimpTxt2colTxtColumn2
            }
            ... on Drupal_ParagraphSimpleImage {
              id
              fieldSimpleImageDesktop {
                alt
                url
              }
              fieldSimpleImageMobile {
                alt
                url
              }
            }
            ... on Drupal_ParagraphStandardQuoteWithAuthor {
              id
              fieldStandardQuoteAuthorName
              fieldStandardQuoteAuthorText
              fieldStandardQuoteAuthorImgm {
                alt
                url
              }
              fieldStandardQuoteAuthorImgd {
                alt
                url
              }
            }
            ... on Drupal_ParagraphCtaBlock {
              id
              fieldCtaBlockCta {
                url {
                  path
                }
                title
              }
            }
            ...on Drupal_ParagraphStandardSingleVideo {
              fieldStandardSingleVimeoLink {
                uri
              }
            }
            ... on Drupal_ParagraphCardWithImage {
              id
              fieldCardWithImageLayout
              fieldSimpleImageDesktop {
                alt
                title
                url
              }
              fieldSimpleImageMobile {
                alt
                title
                url
              }
              fieldSubtitle
              fieldTitle
              fieldStandardDescription
              fieldStandardCta {
                uri
                title
                url {
                  path
                }
              }
            }
            ... on Drupal_ParagraphModularColumnCards {
              id
              fieldColumnCard {
                entity {
                  ... on Drupal_ParagraphColumnCard {
                    id
                    fieldSubtitle
                    fieldTitle
                    fieldSimpleImageMobile {
                      alt
                      title
                      url
                    }
                    fieldSimpleImageDesktop {
                      alt
                      title
                      url
                    }
                    fieldStandardCta {
                      url {
                        path
                      }
                      title
                    }
                    fieldDescription
                  }
                }
              }
            }
            ... on Drupal_ParagraphModularNewsletter {
              id
              fieldNewsletterTitle
              fieldNewsletterText
              fieldNewsletterType
              fieldNewsletterConfirmation
              fieldNewsletterConditions {
                value
                format
                processed
              }
              fieldNewsletterOrigin
            }
            ... on Drupal_ParagraphProductWidget {
              id
              fieldTitle
              fieldStandardCta {
                url {
                  path
                }
                title
              }
              fieldWineList {
                entity {
                  ... on Drupal_TaxonomyTermWineCategory {
                    tid
                    fieldWproductCatDescription
                    fieldWineProductCategoryName
                  }
                }
              }
            }
          }
        }
      }
    }
    productsQuery: taxonomyTermQuery(
        limit: 1000
        offset: 0
        filter: {conditions: [{operator: EQUAL, field: "vid", value: ["wine_category"]}]}
      ) {
        entities {
          ... on Drupal_TaxonomyTermWineCategory {
            name
            fieldWineCategorySlugUrl
            fieldWineCatPrimaryVintage {
              entity {
                ...on Drupal_NodeWineProduct {
                  nid
                  path {
                    alias
                  }
                  title
                  fieldProductDiscoverColorCta
                  fieldWineProductBackcolor
                  fieldWineProductBackillustrat {
                    url
                  }
                  fieldWineProductShortDesc
                  fieldWineProductName
                  fieldCategoryProductName
                  fieldWineProductDiscoverCta {
                    title
                  }
                  fieldWineProdPackshotShadow {
                    url
                  }
                }
              }
            }
            fieldWineCategoryFromPrice
          }
        }
      }
    eventsQuery: nodeQuery(
        filter: {conditions: [{field: "type", value: "events", operator: EQUAL}, {field: "field_event_country", value: [$marketId], operator: EQUAL}]}
        limit: 1000
      ) {
      entities {
        ... on Drupal_NodeEvents {
          title
          fieldEventStartDate {
            value
            date
          }
          fieldEventEndDate {
            value
            date
          }
          fieldEventCountry {
            targetId
          }
          fieldEventCountryLocation
          fieldEventLocation
          fieldEventCover {
            height
            url
            width
            targetId
            alt
            title
          }
          fieldEventCoverMobile {
            height
            url
            width
            targetId
            alt
            title
          }
          fieldEventDescription
          fieldEventHours
        }
      }
    }
  }
}
`
